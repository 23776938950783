module.exports = {
	en: {
		siteMetadata: {
			title: `Superhuit - Digital communication agency, UX user experience and web development - Lausanne, Switzerland`,
			description: `Superhuit is a digital communication agency in Lausanne, Switzerland. Our goal: Increase your brand's impact on the web.`,
			image: '/images/superhuit-en.png',
		},
		post: `Post`,
		posts: `Posts`,
		myPosts: `My Posts`,
		next: `Next`,
		previous: `Previous`,
		postList: `Post list`,
		readPost: `Read post`,
		services: `Services`,
		selectLanguage: `Select your language`,
		newsletterSubscription: `Subscribe to our Newsletter`,
		teamSlug: 'team',
		aboutAuthor: `About`,
		termsLinks: [
			{ title: 'Terms of Use', url: '/en/terms-of-use/' },
			{ title: 'Privacy Policy', url: '/en/privacy-policy/' },
			{ title: 'Manage Cookies', url: '#manage-cookies' },
		],
	},
	fr: {
		siteMetadata: {
			title: `Superhuit - Agence digitale, branding & web - Lausanne – Genève`,
			description: `Superhuit, agence de communication digitale et web à Lausanne, Genève. Développement de stratégie et de branding, sites Internet, e-commerce ou application.`,
			image: '/images/superhuit-fr.png',
		},
		post: `Article`,
		posts: `Articles`,
		myPosts: `Mes articles`,
		previous: `Précédent`,
		next: `Suivant`,
		postList: `Liste des articles`,
		readPost: `Lire l'article`,
		services: `Services`,
		selectLanguage: `Changer de langue`,
		newsletterSubscription: `Abonner à notre newsletter`,
		teamSlug: 'equipe',
		aboutAuthor: `À propos de`,
		termsLinks: [
			{ title: 'Conditions d’utilisation', url: '/fr/conditions/' },
			{
				title: 'Politique de confidentialité',
				url: '/fr/confidentialite/',
			},
			{ title: 'Gérer les cookies', url: '#manage-cookies' },
		],
	},
};
