import './styles.scss';

import React, { useRef, useEffect, useContext } from 'react';
import useSharedSetter from '@assets/scripts/hooks/useSharedSetter';
import { useSpring, animated, interpolate } from 'react-spring';
import { PageContext } from '@assets/scripts/context/page-context';
import { gsap } from '@assets/scripts/gsap/all';
import useInputDevice from '@assets/scripts/hooks/useInputDevice';

// TODO: make the cursor disappear nicely when mouse leaves the page / document looses focus
// (a mix of ttps://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API and other techniques)

const Cursor = () => {
	// refs
	const refEl = useRef(null);
	const refCursor = useRef(null);
	// vars
	const isInitialPositionSet = useRef(false);
	const {
		tlListMenuOpen,
		tlListMenuClose,
		tlListPageFadeOut,
		tlLabelsOpen,
		tlLabelsClose,
	} = useContext(PageContext);
	const releaseDotPosition = useRef(() => {});
	const releaseDotScale = useRef(() => {});
	const { isUsingTouch } = useInputDevice();

	const releaseCursorPosition = useRef(() => {});
	const releaseCursorAngle = useRef(() => {});
	const releaseCursorScale = useRef(() => {});

	// define springs
	const [dotPosition, setPosition] = useSpring(() => ({
		xy: [0, 0],
		// immediate: !isInitialPositionSet.current,       // didn't work, see next line
		immediate: () => !isInitialPositionSet.current, // as a function, this way it gets called dynamically
		config: { mass: 1, tension: 500, friction: 40 },
	}));

	const [dotScale, setScale] = useSpring(() => ({
		scale: 0,
		config: { mass: 3, tension: 1200, friction: 140 },
	}));

	const captureDotPosition = useSharedSetter(
		// unique name
		'dotPosition',
		// handler - how to run the animation
		(xy) => {
			setPosition({ xy: xy });
			isInitialPositionSet.current = true;
		},
		// initial value
		[0, 0]
	);

	const captureDotScale = useSharedSetter(
		'dotScale',
		(s) => setScale({ scale: s }),
		0
	);

	// cursor
	const [cursorPosition, setCursorPosition] = useSpring(() => ({
		xy: [0, 0],
		immediate: true,
	}));

	const [cursorAngle, setCursorAngle] = useSpring(() => ({
		angle: 29,
		config: { mass: 3, tension: 400, friction: 50 },
	}));

	const [cursorScale, setCursorScale] = useSpring(() => ({
		scale: 0,
		config: { mass: 3, tension: 400, friction: 50 },
	}));

	const captureCursorPosition = useSharedSetter(
		// unique name
		'cursorPosition',
		// handler - how to run the animation
		(xy) => {
			setCursorPosition({ xy: xy });
		},
		// initial value
		[0, 0]
	);

	/* eslint-disable-next-line no-unused-vars */
	const captureCursorAngle = useSharedSetter(
		'cursorAngle',
		(s) => setCursorAngle({ angle: s }),
		29
	);

	const captureCursorScale = useSharedSetter(
		'cursorScale',
		(s) => setCursorScale({ scale: s }),
		0
	);

	// activate the cursor when using mouse, deactivate when using touch
	useInputDevice(({ touch }) => {
		if (touch) {
			gsap.set(refEl.current, { opacity: 0 });
			gsap.set(refCursor.current, { opacity: 0 });
			releaseDotPosition.current();
			releaseDotScale.current();
			releaseCursorPosition.current();
			releaseCursorAngle.current();
			releaseCursorScale.current();
		} else {
			gsap.set(refEl.current, { opacity: 1 });
			gsap.set(refCursor.current, { opacity: 1 });
			releaseDotPosition.current = captureDotPosition(
				({ pointerXY }) => pointerXY
			);
			releaseDotScale.current = captureDotScale(() => 0);
			releaseCursorPosition.current = captureCursorPosition(
				({ pointerXY }) => pointerXY
			);
			releaseCursorScale.current = captureCursorScale(() => 0.7);
		}
	});

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		// ⏳ save timeline functions
		tlListMenuOpen.current['cursor'] = tlMenuOpen;
		tlListMenuClose.current['cursor'] = tlMenuClose;
		tlListPageFadeOut.current['cursor'] = tlPageFadeOut;

		return () => {
			delete tlListMenuOpen.current['cursor'];
			delete tlListMenuClose.current['cursor'];
			delete tlListPageFadeOut.current['cursor'];
		};
	}, []);
	/* eslint-enable react-hooks/exhaustive-deps */

	// ✅ Menu Open: cursor animation
	const tlMenuOpen = () => {
		if (isUsingTouch()) return;
		const tl = gsap.timeline();
		tl.to(
			refEl.current,
			{ opacity: 0, duration: 0.2 },
			tlLabelsOpen.showMenu
		);
		return tl;
	};

	// ❌ Menu Close: cursor animation
	const tlMenuClose = () => {
		if (isUsingTouch()) return;

		const tl = gsap.timeline();
		tl.to(
			refEl.current,
			{ opacity: 1, duration: 0.2 },
			tlLabelsClose.showPage
		);
		return tl;
	};

	// ❌ Page Fade Out: cursor animation
	const tlPageFadeOut = () => {
		if (isUsingTouch()) return;

		const tl = gsap.timeline();
		tl.to(refEl.current, { scale: 0, display: 'none', duration: 0.3 }, 0);
		return tl;
	};

	// TODO hide cursor on tablet, take into account isMenuOpen value
	// const onResize = () => {
	// 	if (viewport.isTablet()) {
	// 		gsap.set(refEl.current, { opacity: 0 });
	// 	} else {
	// 		gsap.set(refEl.current, { opacity: 1 });
	// 	}
	// };

	// --------------------------------
	// #endregion
	// --------------------------------

	return (
		<>
			<animated.div
				className="arrow"
				ref={refCursor}
				style={{
					transform: interpolate(
						[cursorAngle.angle, cursorScale.scale],
						(a, s) => `scale(${s}) rotate(${a}deg)`
					),
					top: interpolate([cursorPosition.xy], ([x, y]) => `${y}px`),
					left: interpolate(
						[cursorPosition.xy],
						([x, y]) => `${x}px`
					),
					transformOrigin: '0 0',
				}}
			/>
			<animated.div
				className="cursor"
				data-cursor
				ref={refEl}
				style={{
					transform: interpolate(
						[dotScale.scale],
						(s) => `scale(${s})`
					),
					top: interpolate([dotPosition.xy], ([x, y]) => `${y}px`),
					left: interpolate([dotPosition.xy], ([x, y]) => `${x}px`),
				}}
			/>
		</>
	);
};

export default Cursor;
