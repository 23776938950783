import React, { useContext, useRef } from 'react';
import { PageContext } from '@assets/scripts/context/page-context';
import TransitionLink from 'gatsby-plugin-transition-link';
import Link from 'gatsby-link';
import useCombinedRefs from '@assets/scripts/hooks/useCombinedRefs';

const LinkPage = React.forwardRef(
	({ className, to, children, ...otherProps }, forwardedRef) => {
		const { tlPageOutTransition } = useContext(PageContext);
		const innerRef = useRef(null);
		const refTarget = useCombinedRefs(forwardedRef, innerRef);
		const storybookEnvironment = process.env.STORYBOOK_ENVIRONMENT;
		const buttonInner = <>{children}</>;

		if (storybookEnvironment) {
			return (
				<Link
					className={className}
					to={to}
					{...otherProps}
					ref={refTarget}
				>
					{buttonInner}
				</Link>
			);
		}
		return (
			<TransitionLink
				className={className}
				to={to}
				{...otherProps}
				exit={{
					trigger: () => tlPageOutTransition(),
					length: 0.55,
				}}
				entry={{
					delay: 0.65,
					length: 0.65,
				}}
				ref={refTarget}
				preventScrollJump
			>
				{buttonInner}
			</TransitionLink>
		);
	}
);

LinkPage.displayName = 'LinkPage';

export default LinkPage;
