import './styles.scss';

import React, { useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Link from '@components/atoms/LinkMenu';
import LinkPage from '@components/atoms/LinkPage';
import cx from 'classnames';
import HeaderToggle from './HeaderToggle';
import { PageContext } from '@assets/scripts/context/page-context';
import { gsap, CustomEase } from '@assets/scripts/gsap/all';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { getElementOffsetTop } from '@assets/scripts/helpers/getElementOffsetTop';
import { getElementOffsetLeft } from '@assets/scripts/helpers/getElementOffsetLeft';
import useHover from '@assets/scripts/hooks/useHover';
import useViewport from '@assets/scripts/hooks/useViewport';

// data
import languages from '@data/languages';
import i18n from '@data/i18n';
import menuData from '@data/menu';

// components
import ButtonSecondary from '@components/atoms/buttons/ButtonSecondary';

// assets
import imgLogo from '@assets/images/superhuit_gray.svg';
import iconFacebook from '@assets/icons/facebook.svg';
import iconTwitter from '@assets/icons/twitter.svg';
import iconInstagram from '@assets/icons/instagram.svg';
import iconBehance from '@assets/icons/behance.svg';
import iconLinkedin from '@assets/icons/linkedin.svg';
// vars
const CLASS_NAV_OPENED = 'is-opened';

// --------------------------------
// #region Data
// --------------------------------

const socialLinks = [
	{
		title: 'Facebook',
		icon: iconFacebook,
		url: 'https://www.facebook.com/Superhuit/',
	},
	{
		title: 'Twitter',
		icon: iconTwitter,
		url: 'https://twitter.com/superhuit',
	},
	{
		title: 'Instagram',
		icon: iconInstagram,
		url: 'https://www.instagram.com/superhuit_agency/',
	},
	{
		title: 'Behance',
		icon: iconBehance,
		url: 'https://www.behance.net/superhuit_agency',
	},
	{
		title: 'LinkedIn',
		icon: iconLinkedin,
		url: 'https://pt.linkedin.com/company/superhuit-s-rl',
	},
];

// --------------------------------
// #endregion
// --------------------------------

const Header = ({
	modifiers,
	className,
	menuTitle,
	socialLinks,
	location,
	translations,
}) => {
	gsap.registerPlugin(CustomEase);
	// refs
	const refEl = useRef(null);
	const refGradient = useRef(null);
	const refNav = useRef(null);
	const refSecondaryTitle = useRef(null);
	const refLang = useRef(null);
	const refPrimaryLinks = useRef([]);
	const refSecondaryLinks = useRef([]);
	const refPrimaryLinksSpan = useRef([]);
	const refSecondaryLinksSpan = useRef([]);
	const refOtherLinks = useRef([]);
	const refSocialLinks = useRef([]);
	const refMenuDot = useRef();
	// vars
	const {
		isMenuOpen,
		tlListMenuOpen,
		tlListMenuClose,
		tlListMenuPageTransition,
		tlLabelsOpen,
		tlLabelsClose,
		currentLanguage,
	} = useContext(PageContext);
	const gradientMenuDot = useRef({
		x: 0,
		y: 0,
		scale: 0,
		radius: 0,
	});
	const baseClass = 'header';
	const rootClass = cx(
		baseClass,
		className,
		{
			[`${baseClass}--${modifiers}`]: modifiers,
		},
		isMenuOpen && CLASS_NAV_OPENED
	);
	const homeUrl = languages.find(
		(language) => language.slug === currentLanguage
	).url;
	// const homeUrl = '/en';
	const headerHeightRef = useRef('100vh');

	// interaction
	const [hoverOn, hoverOff] = useHover();

	// --------------------------------
	// #region Hooks
	// --------------------------------

	// when viewport size changes
	useViewport(({ viewportChanged }) => {
		if (viewportChanged) {
			updateHeader();
		}
	});

	// 📢 setup component
	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		updateHeader();
		// ⏳ save timeline functions
		tlListMenuOpen.current['header'] = tlMenuOpen;
		tlListMenuClose.current['header'] = tlMenuClose;
		tlListMenuPageTransition.current['header'] = tlMenuFadeOut;

		return () => {
			delete tlListMenuOpen.current['header'];
			delete tlListMenuClose.current['header'];
			delete tlListMenuPageTransition.current['header'];
		};
	}, []);
	/* eslint-enable react-hooks/exhaustive-deps */

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		gradientMenuDot.current.scale = isMenuOpen ? 1 : 0;
	}, [isMenuOpen]);
	/* eslint-enable react-hooks/exhaustive-deps */

	// --------------------------------
	// #endregion
	// --------------------------------

	// --------------------------------
	// #region Functions
	// --------------------------------

	const updateHeader = () => {
		gradientMenuDot.current.x =
			getElementOffsetLeft(refMenuDot.current) +
			refMenuDot.current.offsetWidth / 2;
		gradientMenuDot.current.y =
			getElementOffsetTop(refMenuDot.current) +
			refMenuDot.current.offsetWidth / 2;
		gradientMenuDot.current.radius =
			window.innerHeight > window.innerWidth
				? window.innerWidth
				: window.innerHeight;

		refGradient.current.style.background = ''; // Remove previous background style to force new one

		gsap.set(refGradient.current, {
			x: gradientMenuDot.current.x - gradientMenuDot.current.radius,
			y: gradientMenuDot.current.y - gradientMenuDot.current.radius,
			width: gradientMenuDot.current.radius * 2,
			height: gradientMenuDot.current.radius * 2,
			background: `radial-gradient(${gradientMenuDot.current.radius}px circle at center, #FF0000 0%, transparent 100%)`,
			scale: gradientMenuDot.current.scale,
		});

		// Fix 100vh for ios mobile
		let isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
		if (isIOS) headerHeightRef.current = `${window.innerHeight}px`;
	};

	// ✅ Menu Open: header animation
	const tlMenuOpen = () => {
		const tl = gsap.timeline();
		tl
			// scroll
			.call(
				() => {
					window.scroll({ top: 0, left: 0, behavior: 'smooth' });
				},
				null,
				0
			)
			// disable body scroll - avoid two scrollbars
			.set(
				refEl.current,
				{
					overflowX: 'hidden',
					width: '100vw',
				},
				0
			)
			.call(() => {
				disableBodyScroll(refEl.current, {
					reserveScrollBarGap: true,
				});
			}, 0)
			// setup
			.set(
				[refPrimaryLinksSpan.current, refSecondaryLinksSpan.current],
				{
					y: '100%',
				},
				0
			)
			.set(
				[
					refSecondaryTitle.current,
					refOtherLinks.current,
					refSocialLinks.current,
				],
				{
					opacity: '0',
				},
				0
			)
			// disable body scroll - avoid two scrollbars
			.set(
				refEl.current,
				{
					height: headerHeightRef.current,
				},
				0
			)
			.set(refNav.current, { display: 'grid' }, 0)
			// gradient
			.to(
				refGradient.current,
				{
					duration: 0.75,
					scale: 1,
					ease: CustomEase.create('custom', '0.57, 0, 0, 1'),
					overwrite: 'auto',
				},
				tlLabelsOpen.showMenu
			)
			// primary menu - links
			.to(
				refPrimaryLinksSpan.current,
				{
					duration: 0.62,
					ease: CustomEase.create('custom', '0.22, 0, 0, 1'),
					stagger: 0.08,
					y: '0%',
				},
				tlLabelsOpen.showContent
			)
			// primary menu - active link
			.to(
				refEl.current,
				{
					duration: 0.38,
					ease: CustomEase.create('custom', '0.33, 0, 0.2, 1'),
					'--activeScale': '1',
					'--hoverScale': '1',
				},
				'>-0.6'
			)
			// secondary menu - title
			.to(
				refSecondaryTitle.current,
				{
					duration: 0.53,
					ease: 'none',
					opacity: '1',
				},
				tlLabelsOpen.showContent + 0.23
			)
			// secondary menu - links
			.to(
				refSecondaryLinksSpan.current,
				{
					duration: 0.52,
					ease: CustomEase.create('custom', '0.22, 0, 0, 1'),
					stagger: 0.08,
					y: '0%',
				},
				tlLabelsOpen.showContent + 0.35
			)
			// secondary menu - active link
			.to(
				refEl.current,
				{
					duration: 0.38,
					ease: CustomEase.create('custom', '0.33, 0, 0.2, 1'),
					'--activeSecondaryScale': '1',
					'--hoverSecondaryScale': '1',
				},
				'>-0.6'
			)
			// languages link
			.to(
				refLang.current,
				{
					duration: 0.28,
					ease: 'none',
					opacity: '1',
					display: 'flex',
				},
				tlLabelsOpen.showContent + 0.28
			)
			// others menu - links
			.to(
				refOtherLinks.current,
				{
					duration: 0.32,
					ease: 'none',
					stagger: 0.08,
					opacity: '1',
				},
				tlLabelsOpen.showContent + 0.33
			)
			// social menu - links
			.to(
				refSocialLinks.current,
				{
					duration: 0.5,
					ease: 'none',
					stagger: 0.04,
					opacity: '1',
				},
				tlLabelsOpen.showContent + 0.3
			);

		return tl;
	};

	// ❌ Menu Close: header animation
	const tlMenuClose = () => {
		const tl = gsap.timeline();
		tl
			// enable body scroll - avoid two scrollbars
			.call(
				() => {
					enableBodyScroll(refEl.current);
				},
				null,
				null,
				0
			)
			// secondary menu - links
			.to(
				refSecondaryLinksSpan.current,
				{
					duration: 0.42,
					ease: CustomEase.create('custom', '1, 0, 0.88, 1'),
					stagger: -0.06,
					y: '100%',
				},
				tlLabelsClose.hideContent
			)
			// secondary menu - active link
			.to(
				refEl.current,
				{
					duration: 0.2,
					ease: CustomEase.create('custom', '0, 0.8, 0.67, 1'),
					'--activeSecondaryScale': '0',
					'--hoverSecondaryScale': '0',
				},
				tlLabelsClose.hideContent
			)
			// secondary menu - title
			.to(
				refSecondaryTitle.current,
				{
					duration: 0.43,
					ease: 'none',
					opacity: '0',
				},
				tlLabelsClose.hideContent + 0.12
			)
			// languages link
			.to(
				refLang.current,
				{
					duration: 0.18,
					ease: 'none',
					opacity: '0',
				},
				tlLabelsClose.hideContent
			)
			// others menu - links
			.to(
				refOtherLinks.current,
				{
					duration: 0.22,
					ease: 'none',
					stagger: -0.06,
					opacity: '0',
				},
				tlLabelsClose.hideContent
			)
			// social menu - links
			.to(
				refSocialLinks.current,
				{
					duration: 0.4,
					ease: 'none',
					stagger: -0.02,
					opacity: '0',
				},
				tlLabelsClose.hideContent
			)
			// primary menu - active link
			.to(
				refEl.current,
				{
					duration: 0.2,
					ease: CustomEase.create('custom', '0, 0.8, 0.67, 1'),
					'--activeScale': '0',
					'--hoverScale': '0',
				},
				tlLabelsClose.hideMenu
			)
			// primary menu - links
			.to(
				refPrimaryLinksSpan.current,
				{
					duration: 0.42,
					ease: CustomEase.create('custom', '1, 0, 0.88, 1'),
					stagger: -0.06,
					y: '100%',
				},
				tlLabelsClose.hideMenu
			)
			// gradient
			.to(
				refGradient.current,
				{
					duration: 0.75,
					scale: 0,
					ease: CustomEase.create('custom', '1, 0, 0.43, 1'),
					overwrite: 'auto',
				},
				'>-0.6'
			)
			// enable body scroll - avoid two scrollbars
			.set(refEl.current, {
				height: '',
				overflowY: '',
				overflowX: '',
				width: '',
			})
			.set(refNav.current, { display: '' });
		return tl;
	};

	// ❌ Menu Fade Out: header animation
	const tlMenuFadeOut = () => {
		const tl = gsap.timeline();
		tl
			// secondary menu - links
			.to(
				refSecondaryLinksSpan.current,
				{
					duration: 0.42,
					ease: CustomEase.create('custom', '1, 0, 0.88, 1'),
					stagger: -0.06,
					y: '100%',
				},
				tlLabelsClose.hideContent
			)
			// secondary menu - active link
			.to(
				refEl.current,
				{
					duration: 0.2,
					ease: CustomEase.create('custom', '0, 0.8, 0.67, 1'),
					'--activeSecondaryScale': '0',
					'--hoverSecondaryScale': '0',
				},
				tlLabelsClose.hideContent
			)
			// secondary menu - title
			.to(
				refSecondaryTitle.current,
				{
					duration: 0.43,
					ease: 'none',
					opacity: '0',
				},
				tlLabelsClose.hideContent + 0.12
			)
			// languages link
			.to(
				refLang.current,
				{
					duration: 0.18,
					ease: 'none',
					opacity: '0',
				},
				tlLabelsClose.hideContent
			)
			// others menu - links
			.to(
				refOtherLinks.current,
				{
					duration: 0.22,
					ease: 'none',
					stagger: -0.06,
					opacity: '0',
				},
				tlLabelsClose.hideContent
			)
			// social menu - links
			.to(
				refSocialLinks.current,
				{
					duration: 0.4,
					ease: 'none',
					stagger: -0.02,
					opacity: '0',
				},
				tlLabelsClose.hideContent
			)
			// primary menu - active link
			.to(
				refEl.current,
				{
					duration: 0.2,
					ease: CustomEase.create('custom', '0, 0.8, 0.67, 1'),
					'--activeScale': '0',
					'--hoverScale': '0',
				},
				tlLabelsClose.hideMenu
			)
			// primary menu - links
			.to(
				refPrimaryLinksSpan.current,
				{
					duration: 0.42,
					ease: CustomEase.create('custom', '1, 0, 0.88, 1'),
					stagger: -0.06,
					y: '100%',
				},
				tlLabelsClose.hideMenu
			)
			// gradient
			.to(
				refGradient.current,
				{
					duration: 0.75,
					scale: 0,
					ease: CustomEase.create('custom', '1, 0, 0.43, 1'),
					overwrite: 'auto',
				},
				'>-0.6'
			)
			.to(
				refEl.current,
				{
					duration: 0.18,
					ease: 'none',
					opacity: '0',
				},
				'>-0.2'
			)
			// enable body scroll - avoid two scrollbars
			.set(refEl.current, {
				height: '',
				overflowY: '',
				overflowX: '',
				width: '',
			})
			.set(refNav.current, { display: '' });
		return tl;
	};

	// --------------------------------
	// #endregion
	// --------------------------------

	// --------------------------------
	// #region Render
	// --------------------------------

	const languageItems = languages.map((language, index) => {
		return (
			<Link
				key={`lang-${index}`}
				to={
					currentLanguage === language.slug
						? location.pathname
						: translations?.find(
								({ lang }) => lang === language.slug
						  )?.uri ?? language.url
				}
				className={currentLanguage === language.slug ? 'is-active' : ''}
			>
				{language.slug}
			</Link>
		);
	});

	const primaryLinksItems = menuData.primaryLinks[currentLanguage].map(
		({ title, link }, index) => {
			const className =
				location.pathname === link.url
					? 'primary__link is-active'
					: 'primary__link';

			return (
				<Link
					key={`link-${index}`}
					className={className}
					to={link.url}
					target={link.targetBlank ? '_blank' : '_self'}
					rel="noreferrer noopener"
					ref={(el) => {
						refPrimaryLinks.current[index] = el;
					}}
				>
					<span
						ref={(el) => {
							refPrimaryLinksSpan.current[index] = el;
						}}
					>
						{title}
					</span>
				</Link>
			);
		}
	);

	const secondaryLinksItems = menuData.secondaryLinks[currentLanguage].map(
		({ title, link }, index) => {
			const className =
				location.pathname === link.url
					? 'secondary__link is-active'
					: 'secondary__link';

			return (
				<Link
					key={`link-${index}`}
					className={className}
					to={link.url}
					target={link.targetBlank ? '_blank' : '_self'}
					rel="noreferrer noopener"
					ref={(el) => {
						refSecondaryLinks.current[index] = el;
					}}
				>
					<span
						className="link-translate"
						ref={(el) => {
							refSecondaryLinksSpan.current[index] = el;
						}}
					>
						<span className="link-underline">{title}</span>
					</span>
				</Link>
			);
		}
	);

	const tertiaryLinksItems = menuData.tertiaryLinks[currentLanguage].map(
		(link, index) => {
			const className =
				location.pathname === link.link.url ? 'is-active' : '';

			return (
				<ButtonSecondary
					className={className}
					key={`tertiary-link-${index}`}
					ref={(el) => {
						refOtherLinks.current[index] = el;
					}}
					href={link.link.url}
					text={link.title}
					context="menu"
				/>
			);
		}
	);

	const socialLinksItems = socialLinks.map(({ url, icon }, index) => {
		return (
			<a
				key={`social-link-${index}`}
				className="social__link"
				href={url}
				target="_blank"
				rel="noreferrer noopener"
				ref={(el) => {
					refSocialLinks.current[index] = el;
				}}
			>
				<svg className="icon">
					<use xlinkHref={`#${icon.id}`} />
				</svg>
			</a>
		);
	});

	return (
		<header
			className={rootClass}
			ref={refEl}
			data-header
			data-animation-page
		>
			<div className="header__navbar grid">
				{isMenuOpen ? (
					<Link
						to={homeUrl}
						className="header__logo"
						aria-label="Superhuit Homepage"
						data-target
					>
						<img
							src={imgLogo}
							alt="Superhuit Logo"
							loading="lazy"
						/>
					</Link>
				) : (
					<LinkPage
						to={homeUrl}
						className="header__logo"
						aria-label="Superhuit Homepage"
						data-target
						onMouseEnter={() => hoverOn()}
						onMouseLeave={() => hoverOff()}
					>
						<img
							src={imgLogo}
							alt="Superhuit Logo"
							loading="lazy"
						/>
					</LinkPage>
				)}
				<HeaderToggle title={menuTitle} ref={refMenuDot} />
				<nav
					className="header__lang"
					aria-label="Languages"
					ref={refLang}
				>
					{languageItems}
				</nav>
			</div>

			<div
				ref={refNav}
				id="header__nav"
				className="header__nav grid"
				aria-expanded={isMenuOpen}
			>
				<nav className="nav__primary" aria-label="Main Pages">
					{primaryLinksItems}
				</nav>
				<nav className="nav__secondary" aria-label="Services">
					<p className="secondary__title" ref={refSecondaryTitle}>
						{i18n[currentLanguage].services}
					</p>
					{secondaryLinksItems}
				</nav>
				<div className="nav__footer">
					<nav className="nav__others" aria-label="Other Links">
						{tertiaryLinksItems}
					</nav>
					<nav className="nav__social" aria-label="Social">
						{socialLinksItems}
					</nav>
				</div>
			</div>
			<div className="header__gradient-wrapper">
				<span
					className="header__gradient"
					ref={refGradient}
					aria-hidden="true"
				></span>
			</div>
		</header>
	);

	// --------------------------------
	// #endregion
	// --------------------------------
};

Header.defaultProps = {
	menuTitle: 'Menu',
	socialLinks,
};

Header.propTypes = {
	modifiers: PropTypes.string,
	className: PropTypes.string,
	menuTitle: PropTypes.string,
	socialLinks: PropTypes.array,
	location: PropTypes.object,
};

export default Header;
