/**
 * README
 * The goal is to have the most performant and clean way for any component to react to mouse proximity and position.
 * To have the freedom of animating as it wants, a component should be aware of the mouse position at each frame.
 * So the plan is to give the possibility to any component to subscribe/unsubscribe from the requestAnimationFrame ticks.
 * - only one event lister (for mousemove)
 * - only one requestAnimationFrame, which in turns calls all the subscribed callbacks
 *
 * What we want to avoid:
 * - unnecessary re-renders (/!\ state changes inside a component or a hook it uses will trigger a re-render so we want to avoid that)
 *
 * Inspirations, useful resources:
 * - https://css-tricks.com/using-requestanimationframe-with-react-hooks/ for a first understanding of requestAnimationFrame and react
 * - https://medium.com/@ryardley/react-hooks-not-magic-just-arrays-cd4f1857236e to feel at home with react hooks
 * - https://github.com/rob2d/use-viewport-sizes/blob/master/src/index.js#L37 for the callbacks registered to only one real event listener
 * - https://medium.com/@paul_irish/requestanimationframe-scheduling-for-nerds-9c57f7438ef4 to dig more into web performance
 *
 * TODO: document better and extract into its own module?
 * - recommendation: avoid dom reading as much as possible in updaters, and absolutely avoid in setters (https://developers.google.com/web/fundamentals/performance/rendering/avoid-large-complex-layouts-and-layout-thrashing)
 */

import { useEffect } from 'react';

// --------------------------------
// #region State
// --------------------------------

let pointerXY = [0, 0];
let touchXY = [0, 0];
let scrollXY = [0, 0];
let viewportWH = [0, 0];
let frameId;

let setters = (() => {
	const _raw = []; // sort order: last attached comes first
	let _toRun = {}; // _raw -> filter to get only 1 per setter

	const keys = () => Object.keys(_toRun);
	const get = (key) => _toRun[key];
	const update = () => (_toRun = _getRunSequence(_raw));

	const add = (setter) => {
		_raw.unshift(setter); // insert at the begining to keep the order (last attached comes first)
		update();
	};

	const remove = (setter) => {
		const index = _raw.indexOf(setter);
		if (index === -1) return;
		_raw.splice(index, 1);
		update();
	};

	const _getRunSequence = function (arr) {
		// keep only the latest updater for each key
		// …we want it to be the only one to run, to take precedence over others
		// …this is to make it all work with stuff like gatsby-plugin-transition-link for example,
		//  where a new component gets mounted before the old one is unmounted
		const filtered = {};
		arr.forEach((x) => {
			if (filtered.hasOwnProperty(x.key)) return; // skip if key already exists
			filtered[x.key] = x;
		});

		return filtered;
	};

	return {
		keys,
		get,
		add,
		remove,
	};
})();

let updaters = (() => {
	const _raw = []; // sort order: last attached comes first
	let _toRun = {}; // _raw -> filter to get only 1 per setter

	const get = (key) => _toRun[key];
	const size = () => _raw.length;
	const update = () => (_toRun = _getRunSequence(_raw));

	const add = (updater) => {
		_raw.unshift(updater); // insert at the begining to keep the order (last attached comes first)
		update();
	};

	const remove = (updater) => {
		const index = _raw.indexOf(updater);
		if (index === -1) return;
		_raw.splice(index, 1);
		update();
	};

	const _getRunSequence = function (arr) {
		// keep only the latest updater for each key
		// …we want it to be the only one to run, to take precedence over others
		const filtered = {};
		_raw.forEach((x) => {
			if (filtered.hasOwnProperty(x.key)) return; // skip if key already exists
			filtered[x.key] = x.cb;
		});

		return filtered;
	};

	return {
		get,
		add,
		remove,
		size,
	};
})();

// --------------------------------
// #endregion
// --------------------------------

// --------------------------------
// #region Helpers
// --------------------------------

function askForAnUpdate() {
	if (frameId) return;
	frameId = requestAnimationFrame(onTick);
}

function isSame(oldVal, val) {
	// type has changed
	if (typeof val !== typeof oldVal) {
		// TODO: add warnings in dev mode only
		// console.warn(
		// 	`The type of value changed. We recommend to use always the same type to avoid unexpected behaviours.`,
		// 	oldVal,
		// 	val
		// );
		return false;
	}

	// array comparison
	if (Array.isArray(val)) {
		return (
			val.length === oldVal.length &&
			val.every((value, index) => value === oldVal[index])
		);
	}

	// default
	return oldVal === val;
}

function isValidValue(val) {
	return (
		(typeof val !== 'object' || Array.isArray(val)) && // typeof returns 'object' for arrays so we need to check specifically
		typeof val !== 'function'
	);
}

// --------------------------------
// #endregion
// --------------------------------

// --------------------------------
// #region Event handlers
// --------------------------------

/**
 * On pointer (mouse) move
 * @param {Event} ev original dom event
 */
function onPointerMove(ev) {
	pointerXY = [ev.clientX, ev.clientY];
	askForAnUpdate();
}

function onTouchMove(ev) {
	touchXY = [ev.touches[0].clientX, ev.touches[0].clientY];
	askForAnUpdate();
}

/**
 * On viewport resize
 */
function onResize() {
	// Problem: if overflow-x exists window.innerWidth returns value with overflow
	// Solution: use document.documentElement.clientWidth
	viewportWH = [document.documentElement.clientWidth, window.innerHeight];
	askForAnUpdate();
}

/**
 * On scroll change
 */
function onScroll() {
	scrollXY = [window.pageXOffset, window.pageYOffset];
	askForAnUpdate();
}

// --------------------------------
// #endregion
// --------------------------------

/**
 * Recursive loop that runs on each requestanimationframe
 * - check if should update or not
 * - runs updaters to update state
 * - runs setters to apply animations
 * - calls itself to start over on next frame (recursive)
 */
function onTick() {
	// bail early if no need to update
	if (!frameId) return;

	// clear frameId
	frameId = undefined;

	// calculated values
	const scrollXYClampedTop = [scrollXY[0], scrollXY[1] < 0 ? 0 : scrollXY[1]];

	// update setters values
	// console.log(setters.keys()); // to debug which shared setters are active
	setters.keys().forEach((key) => {
		const setter = setters.get(key);
		const updater = updaters.get(key);
		let val;
		// update value from the current updater ('capture' function)
		if (typeof updater === 'undefined') {
			val = setter.defaultVal;
		} else {
			val = updater({
				pointerXY,
				touchXY,
				scrollXY,
				scrollXYClampedTop,
				viewportWH,
				previousValue: setter.val,
			});
		}

		// throw an error if value is not valid
		if (!isValidValue(val)) {
			throw new Error(
				`For performance purpose, please use only primitives (string, number, boolean) or arrays of primitives for values.`
			);
		}

		// update the hasChanged flag to indicate if setter callback should run
		setter.hasChanged = !isSame(setter.val, val);

		// update setter value
		setter.val = val;
	});

	// run all setters that need to
	// note: it is important to run setters all at once, after all updaters, at the end of the rAF, to avoid layout thrashing
	//       @see https://developers.google.com/web/fundamentals/performance/rendering/avoid-large-complex-layouts-and-layout-thrashing
	setters.keys().forEach((key) => {
		const setter = setters.get(key);
		if (!setter.hasChanged) return;
		setter.cb(setter.val);
	});
}

/**
 * The Hook
 * @param {string} key the unique key of the setter
 * @param {function|undefined} callback if you want to share a setter: a callback to run when value changes | if you want to use an existing setter: undefined
 * @param {string|number|boolean|array<string>|array<number>|array<boolean>} initialValue the initial value for this setter, which will be set when no updater is active | undefined if you are not sharing a setter
 */
export default function useSharedSetter(
	key,
	callback = undefined,
	initialValue = undefined
) {
	// validate params
	if (typeof key === 'undefined')
		throw Error(
			'You must define a unique key for other components to use this setter.'
		);
	if (typeof callback !== 'undefined' && typeof initialValue === 'undefined')
		throw Error('Please define an initialValue.');

	// vars
	const setter = {
		key,
		cb: callback,
		val: initialValue,
		defaultVal: initialValue,
		hasChanged: false,
	};

	// when component mounts…
	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		// register setter (throw an error if conflicts with an existing one)
		if (typeof setter.cb !== 'undefined') {
			setters.add(setter);
			askForAnUpdate();
		}

		// cleanup when unmounting the component
		return () => {
			if (typeof setter.cb !== 'undefined') {
				setters.remove(setter);
				askForAnUpdate();
			}
		};
	}, [setter]);
	/* eslint-enable react-hooks/exhaustive-deps */

	return ((key) => {
		// return capture function, which in turn has a cleanup function (like react useEffect)
		return (cb) => {
			const updater = {
				key,
				cb,
			};
			updaters.add(updater);
			askForAnUpdate();

			// attach pointer event listener
			// …this happens only when we register the first setter
			// (means the first component using this hook was mounted)
			// it's important to do so, as a hook doesn't have its own lifecycle like components (mount/unmount)
			// scenario example:
			// - components are mounted and use this hook (mousemove is attached on 1st mount)
			// - page change: all components using the hook are unmounted (setters.size === 0 so mousemove is detached, see below)
			// - page change: some components are mounted again and use this hook (mousemove is attached again on 1st mount)
			if (window && updaters.size() === 1) {
				document.addEventListener('mousemove', onPointerMove);
				document.addEventListener('touchstart', onTouchMove);
				document.addEventListener('touchmove', onTouchMove);
				window.addEventListener('resize', onResize);
				window.addEventListener('scroll', onScroll);
				setTimeout(() => {
					// without this timeout, some browsers return a wrong initial value for window dimensions
					// (maybe related to this https://bugzilla.mozilla.org/show_bug.cgi?id=771575#c3)
					onResize();
					onScroll();
				}, 0);
			}

			return ((updater) => () => {
				updaters.remove(updater);
				askForAnUpdate();

				// detach pointer event listener
				// note: this happens only when no updater is registered anymore
				//       (means all components which were using this hook are unmounted)
				if (updaters.size() === 0) {
					document.removeEventListener('mousemove', onPointerMove);
					document.removeEventListener('touchstart', onTouchMove);
					document.removeEventListener('touchmove', onTouchMove);
					window.removeEventListener('resize', onResize);
					window.removeEventListener('scroll', onScroll);
				}
			})(updater);
		};
	})(key);
}
