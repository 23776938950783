/**
 * Get the element offset top
 * Adapted from here https://muffinman.io/javascript-get-element-offset/
 * @param {Object} elementt
 */

export const getElementOffsetTop = (el) => {
	let top = 0;
	let element = el;

	// Loop through the DOM tree
	// and add it's parent's offset to get page offset
	do {
		top += element.offsetTop || 0;
		element = element.offsetParent;
	} while (element);

	return top;
};
