import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import SEO from '@components/SEO';
import useSharedSetter from '@assets/scripts/hooks/useSharedSetter';
import useResizeObserver from '@assets/scripts/hooks/useResizeObserver';
import { useSpring, animated, interpolate } from 'react-spring';

import i18n from '@data/i18n';
import gdpr from '@data/gdpr';
import PageContext from '@assets/scripts/context/page-context';
import Cursor from '@components/atoms/Cursor';
import Gdpr from '@components/molecules/Gdpr';
import Header from '@components/organisms/Header';
import Footer from '@components/organisms/Footer';

const Layout = ({
	children,
	pageClass,
	language,
	translations = [],
	footerRelatedLinks,
	accentColor,
	location,
	type,
	seo,
	title,
}) => {
	const isFakeScroll = type === 'culture';
	const refContentInner = useRef(null);
	const releasePageScroll = useRef(() => {});
	const [contentHeight, setContentHeight] = useState(9999);

	const [pageScroll, setScroll] = useSpring(() => ({
		y: 0,
		config: { mass: 1, tension: 170, friction: 26 },
	}));

	const capturePageScroll = useSharedSetter(
		'pageScroll',
		(y) => {
			setScroll({ y: y });
		},
		0
	);

	// update content height for fake scroll
	const updateContentHeight = useCallback(() => {
		if (!isFakeScroll) return;
		const height = refContentInner.current?.offsetHeight;
		if (!height) return;
		setContentHeight(() => height);
	}, [setContentHeight, isFakeScroll]);

	// when content size changes
	useResizeObserver(refContentInner, updateContentHeight);

	// initialise content height on mount
	useEffect(() => {
		updateContentHeight();
	}, [updateContentHeight]);

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		if (isFakeScroll) {
			releasePageScroll.current = capturePageScroll(
				({ scrollXYClampedTop }) => -scrollXYClampedTop[1]
			);
		}
	}, []);
	/* eslint-enable react-hooks/exhaustive-deps */

	return (
		<PageContext currentLanguage={language}>
			<SEO {...{ title, ...seo, language, translations }} />
			<Helmet
				bodyAttributes={{
					class: `page ${pageClass}`,
				}}
			>
				{accentColor && (
					<style type="text/css">{`
					:root {
						--accent-color: ${accentColor};
					}
			`}</style>
				)}
			</Helmet>

			<div
				className="page__content"
				style={
					isFakeScroll
						? {
								height: contentHeight,
						  }
						: {}
				}
			>
				<animated.div
					ref={refContentInner}
					style={
						isFakeScroll
							? {
									transform: interpolate(
										[pageScroll.y],
										(y) => `translate3d(0px,${y}px,0px)`
									),
									width: '100%',
									position: 'fixed',
							  }
							: {}
					}
				>
					<Header location={location} translations={translations} />
					<main className="page__main">{children}</main>
					<Footer
						termsLinks={i18n[language].termsLinks}
						relatedLinks={footerRelatedLinks}
						newsletterSubscriptionTitle={
							i18n[language].newsletterSubscription
						}
					/>
					<Gdpr configs={gdpr} />
				</animated.div>
				<Cursor />
			</div>
		</PageContext>
	);
};

Layout.defaultProps = {
	accentColor: null,
	type: 'default',
};

Layout.propTypes = {
	children: PropTypes.node,
	language: PropTypes.string.isRequired,
	translations: PropTypes.array,
	accentColor: PropTypes.string,
	type: PropTypes.string,
	location: PropTypes.object,
};

export default Layout;
