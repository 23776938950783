import './styles.scss';

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { PageContext } from '@assets/scripts/context/page-context';
import useHover from '@assets/scripts/hooks/useHover';
import mergeDeep from '@assets/scripts/helpers/merge-deep';
import ButtonPrimary from '@components/atoms/buttons/ButtonPrimary';
import DEFAULT_CONFIG from './default-config.json';
import ButtonSecondary from '@components/atoms/buttons/ButtonSecondary/index';

const GdprBanner = ({
	bannerDismissed,
	texts,
	onAcceptClick,
	onPersonalizeClick,
	onRefuseClick,
}) => {
	const [configs] = useState(() =>
		mergeDeep(DEFAULT_CONFIG, {
			texts,
		})
	);

	const [isHidden, setIsHidden] = useState(true);
	const { currentLanguage } = useContext(PageContext);

	useEffect(() => {
		if (bannerDismissed) hide();
		else show();
	}, [bannerDismissed]);

	const handleAcceptClick = (event) => {
		event.stopPropagation();

		onAcceptClick();

		setIsHidden(true);
	};

	const handleRefuseClick = (event) => {
		event.stopPropagation();

		onRefuseClick();

		setIsHidden(true);
	};

	const show = () => {
		setIsHidden(false);
	};

	const hide = () => {
		setIsHidden(true);
	};

	return (
		<div
			className="banner"
			role="dialog"
			aria-live="polite"
			aria-hidden={isHidden}
			aria-label="cookie-law-banner:title"
			aria-describedby="cookie-law-banner:desc"
		>
			{configs.texts?.[currentLanguage].title ? (
				<p id="cookie-law-banner:title" className="uptitle title">
					{configs.texts?.[currentLanguage].title}
				</p>
			) : null}

			<div id="cookie-law-banner:desc" className="message">
				<p>{configs.texts?.[currentLanguage].message}</p>
			</div>

			<div className="gdpr-actions">
				<ButtonPrimary
					className="acceptButton"
					type="gdpr"
					onClick={handleAcceptClick}
					text={configs.texts?.[currentLanguage].acceptAll}
				/>
				<div className="gdpr-actions-links">
					<ButtonSecondary
						onClick={onPersonalizeClick}
						text={configs.texts?.[currentLanguage].personalize}
						type="gdpr"
						className="personalizeButton"
					/>

					<ButtonSecondary
						text={configs.texts?.[currentLanguage].refuseAll}
						onClick={handleRefuseClick}
						type="gdpr"
						className="refuseButton"
					/>
				</div>
			</div>
		</div>
	);
};

GdprBanner.propTypes = {
	bannerDismissed: PropTypes.bool.isRequired,
	texts: PropTypes.object.isRequired,
	onPersonalizeClick: PropTypes.func.isRequired,
	onAcceptClick: PropTypes.func.isRequired,
};

export default GdprBanner;
