import { useEffect, useRef } from 'react';

const useInitialMount = () => {
	const isInitialMount = useRef(true);

	useEffect(() => {
		isInitialMount.current = false;
	}, []);

	return isInitialMount.current;
};

export { useInitialMount as default };
