import React from 'react';
import Helmet from 'react-helmet';
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';

import { useSiteMetadata } from '@assets/scripts/hooks/useSiteMetadata';

const SEO = ({
	title,
	description,
	image,
	language,
	translations,
	isBlogPost,
}) => {
	const { pathname } = useLocation();
	const site = useSiteMetadata();

	const {
		siteTitle: defaultTitle,
		siteDescription: defaultDescription,
		siteUrl,
		image: defaultImage,
		social: { twitter },
	} = site;

	// force trailing slash
	const _pathname = pathname?.endsWith('/')
		? pathname.slice(0, -1)
		: pathname;
	const url = `${siteUrl}${_pathname}/`;

	const seo = {
		title: title || defaultTitle[language],
		description: description || defaultDescription[language],
		image: `${siteUrl}${image || defaultImage[language]}`,
		url,
	};

	return (
		<React.Fragment>
			<Helmet>
				<html lang={language} />
				{/* General tags */}
				<title>{seo.title}</title>
				<meta name="description" content={seo.description} />
				<link rel="canonical" href={seo.url} />

				{/* Alternate Pages/Languages */}
				{translations.map((translation, index) => (
					<link
						key={`alternate-${index}`}
						rel="alternate"
						href={siteUrl + translation.uri}
						hreflang={translation.lang}
					/>
				))}
				<link
					rel="alternate"
					href={siteUrl + pathname}
					hreflang={language}
				/>

				{/* OpenGraph tags */}
				<meta property="og:url" content={seo.url} />
				<meta
					property="og:type"
					content={isBlogPost ? 'article' : 'website'}
				/>
				<meta property="og:title" content={seo.title} />
				<meta property="og:description" content={seo.description} />
				<meta property="og:image" content={seo.image} />
				<meta property="og:locale" content={language} />
				{translations.map((translation, index) => (
					<meta
						key={`og-alternate-${index}`}
						property="og:locale:alternate"
						content={translation.lang + '_CH'}
					/>
				))}

				{/* Twitter Card tags */}
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:site" content={twitter} />
				<meta name="twitter:creator" content={twitter} />
				<meta name="twitter:title" content={seo.title} />
				<meta name="twitter:description" content={seo.description} />
				<meta name="twitter:image" content={seo.image} />
			</Helmet>
		</React.Fragment>
	);
};

SEO.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	image: PropTypes.string,
	isBlogPost: PropTypes.bool,
	language: PropTypes.string,
	translations: PropTypes.array,
};

SEO.defaultProps = {
	title: null,
	description: null,
	image: null,
	isBlogPost: false,
	language: null, // TODO: get the default site language
	translations: [],
};

export default SEO;
