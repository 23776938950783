import '../button.scss';
import './styles.scss';

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import useHover from '@assets/scripts/hooks/useHover';
import useCombinedRefs from '@assets/scripts/hooks/useCombinedRefs';
// components
import Link from '@components/atoms/LinkPage';
import LinkMenu from '@components/atoms/LinkMenu';

const ButtonSecondary = React.forwardRef(
	({ className, href, text, context, ...otherProps }, forwardedRef) => {
		const rootClass = cx('btn', 'btn-secondary', className);
		// Tests if href string starts either with '/' or '#' -> use <Link> only for internal links!
		// @see https://www.gatsbyjs.org/docs/gatsby-link/#reminder-use-link-only-for-internal-links
		const internal = href?.startsWith('/');

		// refs
		const innerRef = useRef(null);
		const refTarget = useCombinedRefs(forwardedRef, innerRef);

		// interaction
		const [hoverOn, hoverOff] = useHover();

		const buttonInner = (
			<>
				<span className="btn__icon">
					<svg viewBox="0 0 18 12" version="1.1">
						<g fillRule="evenodd">
							<polygon
								id="arrow-stroke"
								points="10 4.952 10 7.047 0 7.04786546 1.28326508e-16 4.95213454"
							></polygon>
							<path
								d="M12.8158065,0 L18,5.83699871 L18,6.18628719 L12.8158065,12 L11.2791743,10.5795602 L13.9386018,7.74025712 C14.2938388,7.37132115 14.6719944,7.09407342 15.1320018,6.829924 L15.3690994,6.69857697 L15.2759702,6.53557568 C14.7210752,6.83441138 13.7357809,7.01584735 12.7863533,7.04401143 L12.528658,7.04786546 L10,7.047 L10,4.952 L12.528658,4.95213454 C13.467711,4.95213454 14.4850185,5.10866753 15.1143144,5.38586136 L15.2759702,5.46442432 L15.3690994,5.30142303 C14.7986829,4.9957956 14.353045,4.69016818 13.9386018,4.25974288 L13.7626203,4.06727038 L11.2791743,1.42043984 L12.8158065,0 Z"
								id="arrow-end"
							></path>
						</g>
					</svg>
				</span>
				<span className="btn__text">
					<div data-boldproof-text={text}>{text}</div>
				</span>
			</>
		);

		if (otherProps.onClick) {
			return (
				<button
					className={rootClass}
					{...otherProps}
					onMouseEnter={() => hoverOn()}
					onMouseLeave={() => hoverOff()}
					ref={refTarget}
				>
					{buttonInner}
				</button>
			);
		}

		if (internal) {
			if (context === 'page') {
				return (
					<Link
						className={rootClass}
						data-target
						to={href}
						{...otherProps}
						onMouseEnter={() => hoverOn()}
						onMouseLeave={() => hoverOff()}
						ref={refTarget}
					>
						{buttonInner}
					</Link>
				);
			} else {
				return (
					<LinkMenu
						className={rootClass}
						to={href}
						{...otherProps}
						ref={refTarget}
					>
						{buttonInner}
					</LinkMenu>
				);
			}
		}
		return (
			<a
				className={rootClass}
				data-target
				href={href}
				{...otherProps}
				onMouseEnter={() => hoverOn()}
				onMouseLeave={() => hoverOff()}
				ref={refTarget}
				target="_blank"
				rel="noopener noreferrer"
			>
				{buttonInner}
			</a>
		);
	}
);

ButtonSecondary.defaultProps = {
	context: 'page',
};

ButtonSecondary.propTypes = {
	className: PropTypes.string,
	href: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	context: PropTypes.string,
};

ButtonSecondary.displayName = 'ButtonSecondary';

export default ButtonSecondary;
