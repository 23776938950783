import { useEffect, useRef } from 'react';

const useCombinedRefs = (...refs) => {
	const targetRef = useRef();
	useEffect(() => {
		refs.forEach((ref) => {
			if (!ref) return;

			if (typeof ref === 'function') {
				ref(targetRef.current);
			} else {
				ref.current = targetRef.current;
			}
		});
	}, [refs]);

	return targetRef;
};

export { useCombinedRefs as default };
