import './styles.scss';

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import useHover from '@assets/scripts/hooks/useHover';
// assets
// import imgFooterTagline from '@assets/images/closed_letter.svg';
import Link from '@components/atoms/LinkPage';

const Footer = ({
	modifiers,
	className,
	relatedLinks,
	newsletterSubscriptionTitle,
	contactTitle,
	termsLinks,
	...otherProps
}) => {
	// vars
	const baseClass = 'footer';
	const rootClass = cx(baseClass, className, {
		[`${baseClass}--${modifiers}`]: modifiers,
	});

	// interaction
	const [hoverOn, hoverOff] = useHover();
	const [bigHoverOn, bigHoverOff] = useHover({ dotScale: 1 });

	const relatedLinksItems = relatedLinks.map((link, index) => {
		return (
			<Link
				key={`related-link-${index}`}
				className="related-links__item"
				to={link.url}
				data-target
				onMouseEnter={() => bigHoverOn()}
				onMouseLeave={() => bigHoverOff()}
			>
				{link.uptitle && (
					<span className="related-links__uptitle">
						{link.uptitle}
					</span>
				)}

				<p
					className="related-links__title"
					dangerouslySetInnerHTML={{
						__html: link.title,
					}}
				/>
			</Link>
		);
	});

	const termsLinksItems = termsLinks.map((link, index) => {
		// anchors
		if (link.url.startsWith('#')) {
			return (
				<a
					key={`terms-link-${index}`}
					className="terms__url footer__link"
					href={link.url}
					data-target
					onMouseEnter={() => hoverOn()}
					onMouseLeave={() => hoverOff()}
				>
					{link.title}
				</a>
			);
		}

		// other links
		return (
			<Link
				key={`terms-link-${index}`}
				className="terms__url footer__link"
				to={link.url}
				data-target
				onMouseEnter={() => hoverOn()}
				onMouseLeave={() => hoverOff()}
			>
				{link.title}
			</Link>
		);
	});

	return (
		<footer className={rootClass} data-animation-page {...otherProps}>
			{relatedLinks.length > 0 && (
				<nav
					className="footer__related-links"
					aria-label="Related Links"
				>
					{relatedLinksItems}
				</nav>
			)}
			<div className="footer__content">
				{/* <div className="footer__newsletter">
					<Link className="newsletter__link" to="/" data-target>
						<img
							className="newsletter__icon"
							src={imgFooterTagline}
							alt=""
							aria-hidden="true"
							role="presentation"
							loading="lazy"
						/>
						<span>{newsletterSubscriptionTitle}</span>
					</Link>
				</div> */}

				<div className="footer__info">
					<address className="footer__office footer__column">
						<span className="office__city footer__title">
							Lausanne
						</span>
						Rue des Terreaux 7<br />
						1003 Lausanne
						<br />
						Switzerland
					</address>
					<div className="footer__contacts footer__column">
						<p className="contacts__title footer__title">
							{contactTitle}
						</p>
						<a
							className="contacts__email footer__link"
							href="mailto:hello@superhuit.ch"
							data-target
							onMouseEnter={() => hoverOn()}
							onMouseLeave={() => hoverOff()}
						>
							hello@superhuit.ch
						</a>
						<a
							className="contacts__phone footer__link"
							href="tel:+41216521818"
							data-target
							onMouseEnter={() => hoverOn()}
							onMouseLeave={() => hoverOff()}
						>
							+41 (0) 21 652 18 18
						</a>
					</div>
					<nav
						className="footer__terms footer__column"
						aria-label="Terms Links"
					>
						{termsLinksItems}
					</nav>
				</div>
			</div>
		</footer>
	);
};

Footer.defaultProps = {
	contactTitle: 'Contact',
	newsletterSubscriptionTitle: 'Subscribe to our Newsletter',
	relatedLinks: [],
	termsLinks: [
		{ title: 'Terms of Use', url: '/en/terms-of-use/' },
		{ title: 'Privacy Policy', url: '/en/privacy-policy/' },
		{ title: 'Manage Cookies', url: '/en/manage-cookies/' },
	],
};

Footer.propTypes = {
	modifiers: PropTypes.string,
	className: PropTypes.string,
	relatedLinks: PropTypes.array,
	newsletterSubscriptionTitle: PropTypes.string,
	contactTitle: PropTypes.string,
	termsLinks: PropTypes.array,
};

export default Footer;
