import { useEffect } from 'react';
import { throttle } from 'lodash-es';

// --------------------------------
// #region State
// --------------------------------

let frameId;
let viewportWH = [0, 0];
let scrollXY = [0, 0];
let viewportChanged = false;
let scrollChanged = false;
let isTablet = false;
const handlers = new Set();
const THROTTLE_DELAY = 100; // ms

// --------------------------------
// #endregion
// --------------------------------

// --------------------------------
// #region Helpers
// --------------------------------
function askForAnUpdate() {
	if (frameId) return;
	frameId = requestAnimationFrame(runUpdates);
}

// --------------------------------
// #endregion
// --------------------------------

// --------------------------------
// #region Event handlers
// --------------------------------

/**
 * On viewport resize
 */
function onResize() {
	// Problem: if overflow-x exists window.innerWidth returns value with overflow
	// Solution: use document.documentElement.clientWidth
	viewportWH = [document.documentElement.clientWidth, window.innerHeight];
	viewportChanged = true;
	isTablet = viewportWH[0] <= 1024;
	askForAnUpdate();
}

/**
 * On scroll change
 */
function onScroll() {
	scrollXY = [window.pageXOffset, window.pageYOffset];
	scrollChanged = true;
	askForAnUpdate();
}

/**
 * On custom change
 * to use when needed
 */
// export function onCustomEvent() {
// 	viewportWH = [window.innerWidth, window.innerHeight];
// 	viewportChanged = true;
// 	isTablet = viewportWH[0] <= 1024;
// 	scrollXY = [window.pageXOffset, window.pageYOffset];
// 	scrollChanged = true;
// 	askForAnUpdate();
// }

// --------------------------------
// #endregion
// --------------------------------

/**
 * Runs on requestanimationframe
 */
function runUpdates() {
	// copy state values to make sure they don't change in the middle of the handlers loop
	const _viewportWH = viewportWH;
	const _scrollXY = scrollXY;
	const _viewportChanged = viewportChanged;
	const _scrollChanged = scrollChanged;
	const _isTablet = isTablet;

	// reset state for upcoming updates
	frameId = false;
	viewportChanged = false;
	scrollChanged = false;
	isTablet = false;

	// run all handlers
	handlers.forEach((handler) => {
		// skip this callback if only scroll changed and it was configured not to care about scroll
		if (!_viewportChanged && !handler.options.scroll) return;
		// run
		handler.callback({
			viewportWH: _viewportWH,
			scrollXY: _scrollXY,
			viewportChanged: _viewportChanged,
			scrollChanged: _scrollChanged,
			isTablet: _isTablet,
		});
	});
}

/**
 * The Hook - registers a callback that will run when viewport size (and optionally scroll) changes
 * @param {function} callback function to run on viewport change, receives args {viewportWH, scrollXY, viewportChanged, scrollChanged}
 * @param {*} options
 */
export default function useViewport(callback, options = {}) {
	const handler = {
		callback,
		options: { ...{ scroll: false }, ...options },
	};

	useEffect(() => {
		handlers.add(handler);

		askForAnUpdate();

		const onScrollThrottled = throttle(onScroll, THROTTLE_DELAY);

		if (window && handlers.size === 1) {
			window.addEventListener('resize', onResize);
			window.addEventListener('scroll', onScrollThrottled);
			// setTimeout(() => {
			// without this timeout, some browsers return a wrong initial value for window dimensions
			// (maybe related to this https://bugzilla.mozilla.org/show_bug.cgi?id=771575#c3)
			onResize();
			onScroll();
			// }, 0);
		}

		// cleanup
		return () => {
			handlers.delete(handler);
			askForAnUpdate();

			if (window && handlers.size === 1) {
				window.removeEventListener('resize', onResize);
				window.removeEventListener('scroll', onScrollThrottled);
			}
		};
	}, [handler]);
}
