import React, { useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import iconClose from '@assets/icons/close.svg';
import { useSpring, animated, interpolate } from 'react-spring';
import { gsap, CustomEase } from '@assets/scripts/gsap/all';
import { PageContext } from '@assets/scripts/context/page-context';
import useSharedSetter from '@assets/scripts/hooks/useSharedSetter';
import useViewport from '@assets/scripts/hooks/useViewport';
import useCombinedRefs from '@assets/scripts/hooks/useCombinedRefs';
import { getElementOffsetTop } from '@assets/scripts/helpers/getElementOffsetTop';
import { getElementOffsetLeft } from '@assets/scripts/helpers/getElementOffsetLeft';
import useInputDevice from '@assets/scripts/hooks/useInputDevice';

const HeaderToggle = React.forwardRef(({ title }, forwardedRef) => {
	// refs
	const refMenuOpen = useCombinedRefs(null);
	const refMenuClose = useRef(null);
	const refToggleBorder = useRef(null);
	const innerRefMenuDot = useRef(null);
	const refMenuDot = useCombinedRefs(forwardedRef, innerRefMenuDot);
	// vars
	const releaseDotPosition = useRef();
	const releaseDotScale = useRef();
	const isMenuOpenRef = useRef(false);
	const {
		isMenuOpen,
		setIsMenuOpen,
		tlListMenuOpen,
		tlListMenuClose,
		tlListMenuPageTransition,
		tlLabelsOpen,
		tlLabelsClose,
	} = useContext(PageContext);
	const measures = useRef({
		menuOpenLeft: undefined, // relative to document
		menuOpenTop: undefined, // relative to document
		menuDotOffsets: undefined,
	});
	const dotInitialXY = useRef();
	gsap.registerPlugin(CustomEase);
	const { isUsingTouch } = useInputDevice();

	useViewport(
		({ viewportWH, viewportChanged }) => {
			const [vw] = viewportWH;
			// doesn't depend on scroll — update only when viewport size changes
			if (
				viewportChanged ||
				typeof measures.current.menuDotOffsets === 'undefined'
			) {
				measures.current.menuOpenLeft = getElementOffsetLeft(
					refMenuOpen.current
				);
				measures.current.menuOpenTop = getElementOffsetTop(
					refMenuOpen.current
				);

				measures.current.menuDotOffsets = {
					left: refMenuDot.current.offsetLeft,
					top: refMenuDot.current.offsetTop,
					width: refMenuDot.current.offsetWidth,
					height: refMenuDot.current.offsetHeight,
				};
			}

			// default position of menu dot, relative to the document
			dotInitialXY.current = [
				measures.current.menuOpenLeft +
					measures.current.menuDotOffsets.left +
					measures.current.menuDotOffsets.width / 2,
				measures.current.menuOpenTop +
					measures.current.menuDotOffsets.top +
					measures.current.menuDotOffsets.height / 2,
			];

			// resize menu toggle border if it goes out of the viewport edges
			const borderDiff =
				refToggleBorder.current.getBoundingClientRect().x +
				refToggleBorder.current.offsetWidth -
				vw;
			if (borderDiff > 0) {
				const borderScaled =
					(refToggleBorder.current.offsetWidth - borderDiff) /
					refToggleBorder.current.offsetWidth;
				gsap.set(refToggleBorder.current, { scaleX: borderScaled });
			}
		},
		{ scroll: true }
	);

	const [dotPosition, setPosition] = useSpring(() => ({
		xy: [0, 0],
		config: {
			mass: 1,
			tension: 42,
			friction: 12,
			easing: (t) => 1 - Math.pow(1 - t, 3),
		},
	}));

	const [dotScale, setScale] = useSpring(() => ({
		scale: 0.5,
		config: { mass: 4, tension: 600, friction: 50 },
	}));

	const captureDotPosition = useSharedSetter(
		// unique name
		'menuDotPosition',
		// handler - how to run the animation
		(xy) => {
			setPosition({ xy: xy });
		},
		// initial value
		[0, 0]
	);

	const captureMenuDotScale = useSharedSetter(
		'menuDotScale',
		(s) => setScale({ scale: s }),
		0.5
	);

	// --------------------------------
	// #region Hooks
	// --------------------------------

	useInputDevice(({ touch }) => {
		if (touch) {
			releaseDot();
		}
	});

	// ⏳ save timeline functions
	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		tlListMenuOpen.current['headerToggle'] = tlMenuOpen;
		tlListMenuClose.current['headerToggle'] = tlMenuClose;
		tlListMenuPageTransition.current['headerToggle'] = tlMenuFadeOut;
		document.addEventListener('keydown', handleKeyEsc);

		return () => {
			delete tlListMenuOpen.current['headerToggle'];
			delete tlListMenuClose.current['headerToggle'];
			delete tlListMenuPageTransition.current['headerToggle'];
			document.removeEventListener('keydown', handleKeyEsc);
		};
	}, []);
	/* eslint-enable react-hooks/exhaustive-deps */

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		isMenuOpenRef.current = isMenuOpen;
	}, [isMenuOpen]);
	/* eslint-enable react-hooks/exhaustive-deps */

	// --------------------------------
	// #endregion
	// --------------------------------

	// --------------------------------
	// #region Functions
	// --------------------------------

	const releaseDot = () => {
		if (typeof releaseDotPosition.current !== 'undefined') {
			releaseDotPosition.current();
			releaseDotPosition.current = undefined;
		}
		if (typeof releaseDotScale.current !== 'undefined') {
			releaseDotScale.current();
			releaseDotScale.current = undefined;
		}
	};

	// ⚡️ mouse enters: attach the menu dot to the position of the mouse
	const mouseEnter = () => {
		if (isUsingTouch()) return;
		releaseDotPosition.current = captureDotPosition(
			({ pointerXY, scrollXY }) => {
				// return default value if measures not yet measured
				if (typeof dotInitialXY.current === 'undefined') return [0, 0];

				return [
					pointerXY[0] - dotInitialXY.current[0] + scrollXY[0], // x axis
					pointerXY[1] - dotInitialXY.current[1] + scrollXY[1], // y axis
				];
			}
		);
		releaseDotScale.current = captureMenuDotScale(() => 1);
	};

	// ⚡️ mouse leave: detach menu dot
	const mouseLeave = () => releaseDot();

	// 🎹 Menu open/close with keyboard
	const handleKeyDown = (ev) => {
		if (ev.key === 'Enter') {
			if (isMenuOpenRef.current) {
				refMenuClose.current.click();
			} else {
				refMenuOpen.current.click();
			}
		}
	};

	// 🎹 Menu close with esc
	const handleKeyEsc = (ev) => {
		if (ev.keyCode === 27) {
			if (isMenuOpenRef.current) {
				refMenuClose.current.click();
			}
		}
	};

	// ✅ Menu Open: header toggle animation
	const tlMenuOpen = () => {
		const tl = gsap.timeline();
		tl.set(refMenuOpen.current, { pointerEvents: 'none' }, 0)
			.set(refMenuClose.current, { pointerEvents: 'auto' }, 0)
			// menu close button
			.to(
				refMenuClose.current,
				{
					duration: 0.84,
					ease: CustomEase.create('custom', '0.86, 0, 0.14, 1'),
					scale: 1,
				},
				tlLabelsOpen.showMenu
			)
			// menu open button
			.to(
				refMenuOpen.current,
				{
					duration: 0.13,
					ease: 'none',
					opacity: 0,
				},
				tlLabelsOpen.showMenu + 0.1
			);
		return tl;
	};

	// ❌ Menu Close: header toggle animation
	const tlMenuClose = () => {
		const tl = gsap.timeline();
		tl
			// menu close button
			.to(
				refMenuClose.current,
				{
					duration: 0.74,
					ease: CustomEase.create('custom', '0.86, 0, 0.14, 1'),
					scale: 0,
				},
				tlLabelsClose.hideMenu
			)
			// menu open button
			.to(
				refMenuOpen.current,
				{
					duration: 0.13,
					ease: 'none',
					opacity: 1,
				},
				tlLabelsClose.showPage
			)
			.set(refMenuOpen.current, { pointerEvents: 'auto' })
			.set(refMenuClose.current, { pointerEvents: 'none' });

		return tl;
	};

	// ❌ Menu Fade Out: header toggle animation
	const tlMenuFadeOut = () => {
		const tl = gsap.timeline();
		tl
			// menu close button
			.to(
				refMenuClose.current,
				{
					duration: 0.74,
					ease: CustomEase.create('custom', '0.86, 0, 0.14, 1'),
					scale: 0,
				},
				tlLabelsClose.hideMenu
			);

		return tl;
	};

	// --------------------------------
	// #endregion
	// --------------------------------

	// --------------------------------
	// #region Render
	// --------------------------------

	return (
		<button
			className="header__nav-toggle"
			aria-label="Toggle menu"
			aria-pressed={isMenuOpen}
			aria-controls="header__nav"
			tabIndex={-1}
		>
			<div
				ref={refMenuOpen}
				className="nav-toggle__button nav-toggle__open"
				role="button"
				aria-label="Open"
				tabIndex={0}
				data-target
				onClick={() => setIsMenuOpen(() => true)}
				onKeyDown={handleKeyDown}
				onMouseEnter={() => mouseEnter()}
				onMouseLeave={() => mouseLeave()}
			>
				<div
					ref={refToggleBorder}
					data-target-border
					style={{
						'--x-border': '50px',
						'--y-border': '60px',
					}}
				></div>
				<div className="open__inner" data-target-inner>
					<span className="open__title">{title}</span>
					<animated.span
						ref={refMenuDot}
						className="open__dot"
						aria-hidden="true"
						style={{
							transform: interpolate(
								[dotPosition.xy, dotScale.scale],
								([x, y], s) =>
									`translate3d(${x}px,${y}px,0) scale(${s})`
							),
							transformOrigin: '50% 50%',
						}}
					/>
				</div>
			</div>
			<div
				ref={refMenuClose}
				className="nav-toggle__button nav-toggle__close"
				role="button"
				aria-label="Close"
				tabIndex={0}
				onClick={() => setIsMenuOpen(() => false)}
				onKeyDown={handleKeyDown}
			>
				<svg className="icon">
					<use xlinkHref={`#${iconClose.id}`} />
				</svg>
			</div>
		</button>
	);

	// --------------------------------
	// #endregion
	// --------------------------------
});

HeaderToggle.defaultProps = {
	title: 'Menu',
};

HeaderToggle.propTypes = {
	title: PropTypes.string,
};

HeaderToggle.displayName = 'HeaderToggle';

export default HeaderToggle;
