import '../button.scss';
import './styles.scss';

import React, { useRef } from 'react';
import cx from 'classnames';
import Link from '@components/atoms/LinkPage';
import { useSpring, animated } from 'react-spring';
import useInputDevice from '@assets/scripts/hooks/useInputDevice';
import useSharedSetter from '@assets/scripts/hooks/useSharedSetter';
import useViewport from '@assets/scripts/hooks/useViewport';
import uniqueId from '@assets/scripts/helpers/uniqueId';
import useHover from '@assets/scripts/hooks/useHover';
// import { distanceFromRectangle } from '@assets/scripts/helpers/geometry';

const GRADIENT_RADIUS = 150; // in pixels
// const BUTTON_SHADOW_DIST = 4; // in pixels, the max translate distance of the shadow

const ButtonPrimary = ({ className, href, text, type, onClick = () => {}, ...otherProps }) => {
	const rootClass = cx('btn', 'btn-primary', className);
	// Tests if href string starts either with '/' or '#' -> use <Link> only for internal links!
	// @see https://www.gatsbyjs.org/docs/gatsby-link/#reminder-use-link-only-for-internal-links
	const internal = href && href.startsWith('/');

	// refs
	const refBackground = useRef(null);
	const refBorder = useRef(null);

	// vars
	const { isUsingKeyboard } = useInputDevice();
	const isMouseInside = useRef(false);
	const isFocused = useRef(false);
	const hasFirstFrameRun = useRef(false);
	const aboutToRelease = useRef();
	const releaseGradientMousePosition = useRef();
	const releaseGradientFocusPosition = useRef();
	const id = useRef(uniqueId('buttonPrimaryGradient'));
	// const releaseMouseShadowPosition = useRef();
	// const releaseFocusShadowPosition = useRef();
	const measures = useRef({
		backgroundXY: [0, 0], // relative to document
		backgroundWH: [0, 0],
		borderXY: [0, 0], // relative to document
		borderWH: [0, 0],
	});

	// interaction
	const [hoverOn, hoverOff] = useHover({ dotScale: 0 });

	// --------------------------------
	// #region Gradient Animation
	// --------------------------------

	// when viewport size changes
	useViewport(
		({ scrollXY }) => {
			getButtonMeasures(scrollXY);
		},
		{ scroll: true } // TODO: how to stop updating in scroll to save performance? for now it doesn't calculate correctly, I guess because everything is not rendered yet
	);

	const getButtonMeasures = (scrollXY) => {
		// measure background (used for gradient)
		const rectBackground = refBackground.current.getBoundingClientRect();
		measures.current.backgroundXY = [
			rectBackground.x + scrollXY[0],
			rectBackground.y + scrollXY[1],
		];
		measures.current.backgroundWH = [
			rectBackground.width,
			rectBackground.height,
		];
	};

	// define spring
	const [gradientPosition, setGradientPosition] = useSpring(() => ({
		xy: [-1 * GRADIENT_RADIUS, 0],
		config: { mass: 5, tension: 750, friction: 140 },
	}));

	// shared setter - here we use it to easily "capture" the setter function,
	// this way it gets registered in a loop and runs at every pointer position change
	const captureGradientPosition = useSharedSetter(
		id.current,
		(xy) => {
			setGradientPosition({
				xy: xy,
				immediate: !hasFirstFrameRun.current,
			});
			hasFirstFrameRun.current = true;
		},
		[-1 * GRADIENT_RADIUS, 0]
	);

	// call this when the mouse leaves the area, to leave time for the gradient to move out smoothly
	// TODO: for situations like this we could instead track the gradient position (with spring `onFrame` callback)
	//       and stop when out of bounds, but would need to check if it doesn't hurt performance
	const scheduleRelease = () => {
		// bail early if already scheduled
		if (typeof aboutToRelease.current !== 'undefined') return;

		aboutToRelease.current = setTimeout(() => {
			// bail early if pointer back inside, or if already released
			if (
				isMouseInside.current ||
				typeof releaseGradientMousePosition.current === 'undefined'
			)
				return;
			releaseGradientMousePosition.current();
			releaseGradientMousePosition.current = undefined;
			hasFirstFrameRun.current = false;
		}, 500);
	};

	const isReleaseScheduled = () => !!aboutToRelease.current;

	const cancelScheduledRelease = () => {
		if (!isReleaseScheduled()) return;
		clearTimeout(aboutToRelease.current);
		aboutToRelease.current = undefined;
	};

	// ⚡️ mouse enters: gradient follows
	const mouseEnterGradient = () => {
		// cancel releasing the shadow if it was scheduled
		cancelScheduledRelease();

		// bail early if gradient position is already captured (= animation already started)
		if (typeof releaseGradientMousePosition.current !== 'undefined') return;

		// capture gradient position (make it follow the pointer)
		hasFirstFrameRun.current = false;
		releaseGradientMousePosition.current = captureGradientPosition(
			({ pointerXY, scrollXY }) => {
				getButtonMeasures(scrollXY);

				if (!isMouseInside.current) scheduleRelease();
				return [
					// pointer position relative to document
					pointerXY[0] +
						scrollXY[0] -
						// el position relative to document
						measures.current.backgroundXY[0],
					// pointer position relative to document
					pointerXY[1] +
						scrollXY[1] -
						// el position relative to document
						measures.current.backgroundXY[1],
				];
			}
		);
	};

	// ⚡️ mouse leaves: hide gradient
	const mouseLeaveGradient = () => {};

	// ⚡️ focus enters: show gradient
	const focusEnterGradient = () => {
		isFocused.current = true;
		// bail early if gradient position is already captured (= animation already started)
		if (typeof releaseGradientFocusPosition.current !== 'undefined') return;

		// make sure first move is NOT immediate
		hasFirstFrameRun.current = true;

		// capture gradient position (make it follow the pointer)
		releaseGradientFocusPosition.current = captureGradientPosition(() => [
			0,
			0,
		]);
	};

	// ⚡️ focus leaves: hide gradient
	const focusLeaveGradient = () => {
		isFocused.current = false;
		// bail early if already released
		if (typeof releaseGradientFocusPosition.current === 'undefined') return;
		releaseGradientFocusPosition.current();
		releaseGradientFocusPosition.current = undefined;
	};

	// --------------------------------
	// #endregion
	// --------------------------------

	// --------------------------------
	// #region Shadow Animation
	// --------------------------------

	// // when viewport size changes
	// useViewport(
	// 	({ scrollXY }) => {
	// 		// measure background (used for gradient)
	// 		const rectBorder = refBorder.current.getBoundingClientRect();
	// 		measures.current.borderXY = [
	// 			rectBorder.x + scrollXY[0],
	// 			rectBorder.y + scrollXY[1],
	// 		];
	// 		measures.current.borderWH = [rectBorder.width, rectBorder.height];
	// 	},
	// 	{ scroll: true } // TODO: how to stop updating in scroll to save performance? for now it doesn't calculate correctly, I guess because everything is not rendered yet
	// );

	// // define spring
	// const [shadowPosition, setShadowPosition] = useSpring(() => ({
	// 	xy: [0, 0],
	// 	config: { mass: 5, tension: 750, friction: 140 },
	// }));

	// // shared setter - here we use it to easily "capture" the setter function,
	// // this way it gets registered in a loop and runs at every pointer position change
	// const captureShadowPosition = useSharedSetter(
	// 	uniqueId('buttonPrimaryShadow'),
	// 	(xy) => {
	// 		setShadowPosition({ xy: xy });
	// 	},
	// 	[0, 0]
	// );

	// // ⚡️ mouse enters: gradient follows
	// const mouseEnterShadow = () => {
	// 	// bail early if shadow position is already captured (= animation already started)
	// 	if (typeof releaseMouseShadowPosition.current !== 'undefined') return;

	// 	// capture shadow position (make it follow the pointer)
	// 	releaseMouseShadowPosition.current = captureShadowPosition(
	// 		({ pointerXY, scrollXY }) => {
	// 			// pointer position relative to document
	// 			const pointerPageXY = [
	// 				pointerXY[0] + scrollXY[0],
	// 				pointerXY[1] + scrollXY[1],
	// 			];

	// 			// calculate proximity
	// 			const dist = distanceFromRectangle(
	// 				pointerPageXY[0],
	// 				pointerPageXY[1],
	// 				measures.current.backgroundXY[0],
	// 				measures.current.backgroundXY[1],
	// 				measures.current.backgroundXY[0] +
	// 					measures.current.backgroundWH[0],
	// 				measures.current.backgroundXY[1] +
	// 					measures.current.backgroundWH[1]
	// 			);
	// 			let progress = dist / 100;
	// 			progress = progress < 0 ? 0 : progress > 1 ? 1 : progress; // make sure it's between 0 and 1

	// 			return [
	// 				BUTTON_SHADOW_DIST - progress * BUTTON_SHADOW_DIST,
	// 				BUTTON_SHADOW_DIST - progress * BUTTON_SHADOW_DIST,
	// 			];
	// 		}
	// 	);
	// };

	// // ⚡️ mouse leaves: hide shadow
	// const mouseLeaveShadow = () => {
	// 	// bail early if already released
	// 	if (typeof releaseMouseShadowPosition.current === 'undefined') return;
	// 	releaseMouseShadowPosition.current();
	// 	releaseMouseShadowPosition.current = undefined;
	// };

	// const focusShadow = () => {
	// 	// bail early if shadow position is already captured (= animation already started)
	// 	if (typeof releaseFocusShadowPosition.current !== 'undefined') return;

	// 	// capture shadow position
	// 	releaseFocusShadowPosition.current = captureShadowPosition(() => [
	// 		-2,
	// 		-2,
	// 	]);
	// };

	// const blurShadow = () => {
	// 	// bail early if already released
	// 	if (typeof releaseFocusShadowPosition.current === 'undefined') return;
	// 	releaseFocusShadowPosition.current();
	// 	releaseFocusShadowPosition.current = undefined;
	// };

	// --------------------------------
	// #endregion
	// --------------------------------

	// ⚡️ mouse enters
	const mouseEnter = () => {
		if (isFocused.current) return;
		isMouseInside.current = true;
		mouseEnterGradient();
		// mouseEnterShadow();
	};

	// ⚡️ mouse leaves
	// TODO: fix this maybe… the problem is, sometimes mouseleave doesn't trigger (ie. if you drag-and-release the button, or if the button is on the edge of the viewport and you escape the button outside the window directly…)
	const mouseLeave = (ev) => {
		// bail early if position still inside
		// (this happens if an element positionned within has a greater z-index for example,
		//  here it happens when we hover the button <a>)
		if (!ev.target || isFocused.current) return; // shouldn't happen
		const rect = ev.target.getBoundingClientRect();
		const imprecisionPx = 1; // nb of pixels to prevent false positives because of browser precision (i.e. cursor position is rounded to 1px while rect is rounded to 0.01px)
		if (
			ev.clientX > rect.x + imprecisionPx &&
			ev.clientX < rect.x + rect.width - imprecisionPx &&
			ev.clientY > rect.y + imprecisionPx &&
			ev.clientY < rect.y + rect.height - imprecisionPx
		)
			return;

		isMouseInside.current = false;
		mouseLeaveGradient();
		// mouseLeaveShadow();
	};

	// ⚡️ focus enters
	const focusEnter = () => {
		isUsingKeyboard() && focusEnterGradient();
	};

	// ⚡️ focus leaves
	const focusLeave = () => {
		focusLeaveGradient();
	};

	const linkTag = (type==="submit" || type==="gdpr") ? (
		<button
			type={type}
			data-boldproof-text={text}
			// onFocus={focusShadow}
			// onBlur={blurShadow}
			onFocus={focusEnter}
			onBlur={focusLeave}
			onMouseEnter={() => hoverOn()}
			onMouseLeave={() => hoverOff()}
			onClick={onClick ? onClick : {}}
		>
			{text}
		</button>
	) : internal ? (
		<Link
			to={href}
			data-boldproof-text={text}
			// onFocus={focusShadow}
			// onBlur={blurShadow}
			onFocus={focusEnter}
			onBlur={focusLeave}
			onMouseEnter={() => hoverOn()}
			onMouseLeave={() => hoverOff()}
		>
			{text}
		</Link>
	) : (
		<a
			href={href}
			data-boldproof-text={text}
			// onFocus={focusShadow}
			// onBlur={blurShadow}
			onFocus={focusEnter}
			onBlur={focusLeave}
			onMouseEnter={() => hoverOn()}
			onMouseLeave={() => hoverOff()}
		>
			{text}
		</a>
	);

	return (
		<div className={rootClass} data-target {...otherProps}>
			{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
			<div
				ref={refBorder}
				data-target-border
				onMouseEnter={mouseEnter}
				onMouseLeave={mouseLeave}
				aria-hidden="true"
				style={{ '--x-border': '100px', '--y-border': '100px' }}
			></div>
			{linkTag}
			<animated.span
				ref={refBackground}
				className="btn-primary__bg"
				style={{
					background: gradientPosition.xy.interpolate(
						(x, y) =>
							`#1f1f1f radial-gradient(${GRADIENT_RADIUS}px circle at ${x}px ${y}px, red 0%, rgba(31, 31, 31, 0) 70%) no-repeat`
					),
				}}
			></animated.span>
			<span
				className="btn-primary__shadow"
				// style={{
				// 	transform: shadowPosition.xy.interpolate(
				// 		(x, y) => `translate3d(${x}px, ${y}px, 0)`
				// 	),
				// }}
			></span>
		</div>
	);
};

export default ButtonPrimary;
