module.exports = [
	{
		slug: 'en',
		url: '/en/',
		default: true,
		assets: {
			months: [
				'january',
				'february',
				'march',
				'april',
				'may',
				'june',
				'july',
				'august',
				'september',
				'october',
				'november',
				'december',
			],
		},
	},
	{
		slug: 'fr',
		url: '/fr/',
		assets: {
			months: [
				'janvier',
				'février',
				'mars',
				'avril',
				'mai',
				'juin',
				'juillet',
				'août',
				'septembre',
				'octobre',
				'novembre',
				'décembre',
			],
		},
	},
];
