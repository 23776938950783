import { useStaticQuery, graphql } from 'gatsby';

export const useSiteMetadata = () => {
	const { site } = useStaticQuery(
		graphql`
			query SiteMetaData {
				site {
					siteMetadata {
						siteUrl
						siteTitle {
							fr
							en
						}
						siteDescription {
							en
							fr
						}
						image {
							en
							fr
						}
						organization {
							logo
							name
							url
						}
						social {
							facebook
							twitter
							instagram
							behance
						}
					}
				}
			}
		`
	);
	return site.siteMetadata;
};
