import { useEffect, useRef } from 'react';
import useSharedSetter from '@assets/scripts/hooks/useSharedSetter';

/**
 * Animate the cursor on hover
 */
export default function useHover({ dotScale = 0.5, cursorScale = 1 } = {}) {
	// vars
	const captureDotScale = useSharedSetter('dotScale');
	const releaseDotScale = useRef(() => {});
	const tm = useRef(null);

	// const captureCursorAngle = useSharedSetter('cursorAngle');
	// const releaseCursorAngle = useRef(() => {});

	const captureCursorScale = useSharedSetter('cursorScale');
	const releaseCursorScale = useRef(() => {});

	const hoverOn = () => {
		// releaseCursorAngle.current = captureCursorAngle(() => cursorAngle);
		releaseCursorScale.current = captureCursorScale(() => cursorScale);
		tm.current = setTimeout(() => {
			releaseDotScale.current = captureDotScale(() => dotScale);
		}, 100);
	};

	const hoverOff = () => {
		// releaseCursorAngle.current();
		if (tm.current) clearTimeout(tm.current);
		releaseDotScale.current();
		releaseCursorScale.current();
	};

	useEffect(() => {
		// cleanup
		return () => {
			hoverOff();
			if (tm.current) clearTimeout(tm.current);
		};
	});

	return [hoverOn, hoverOff];
}
