import React, { useContext, useRef } from 'react';
import { PageContext } from '@assets/scripts/context/page-context';
import TransitionLink from 'gatsby-plugin-transition-link';
import Link from 'gatsby-link';
import useCombinedRefs from '@assets/scripts/hooks/useCombinedRefs';

const LinkMenu = React.forwardRef(
	({ className, to, children, ...otherProps }, forwardedRef) => {
		const { tlMenuTransition } = useContext(PageContext);
		const innerRef = useRef(null);
		const refTarget = useCombinedRefs(forwardedRef, innerRef);
		const storybookEnvironment = process.env.STORYBOOK_ENVIRONMENT;
		const buttonInner = <>{children}</>;

		if (storybookEnvironment) {
			return (
				<Link
					className={className}
					to={to}
					ref={refTarget}
					{...otherProps}
				>
					{buttonInner}
				</Link>
			);
		}
		return (
			<TransitionLink
				className={className}
				to={to}
				{...otherProps}
				exit={{
					trigger: ({ exit, node }) =>
						tlMenuTransition(exit, node, 'exit'),
					length: 1,
				}}
				entry={{
					delay: 1.1,
					length: 1,
				}}
				ref={refTarget}
			>
				{buttonInner}
			</TransitionLink>
		);
	}
);

LinkMenu.displayName = 'LinkMenu';

export default LinkMenu;
