import { useEffect } from 'react';

let count = 0;
let handlers = new Set();
let _hasUserTouchedForReal = false;
let _isUsingTouch = undefined;
let _isUsingMouse = undefined;
let _isUsingKeyboard = undefined;

// event handlers
const onTouchStart = () => {
	// ignore mouse events from now on, since they sometimes trigger on touch as well…
	// -> for someone using touch and mouse on the same device, no way to go back to using the mouse :(
	_hasUserTouchedForReal = true;

	const wasTouchDevice = _isUsingTouch;
	_isUsingTouch = true;
	_isUsingMouse = false;
	_isUsingKeyboard = false;
	if (wasTouchDevice !== _isUsingTouch) {
		runHandlers();
	}
};
const onMouseEvent = () => {
	if (_hasUserTouchedForReal) return;

	const wasUsingMouse = _isUsingMouse;
	_isUsingMouse = true;
	_isUsingTouch = false;
	_isUsingKeyboard = false;
	if (wasUsingMouse !== _isUsingMouse) {
		runHandlers();
	}
};
const onKeyDown = () => {
	const wasUsingKeyboard = _isUsingKeyboard;
	// IMPORTANT: don't change mouse/touch here, we want to keep mouse/touch behavior & styles while using keyboard
	_isUsingKeyboard = true;
	if (wasUsingKeyboard !== _isUsingKeyboard) {
		runHandlers();
	}
};

// exposed singleton methods
const isUsingTouch = () => _isUsingTouch;
const isUsingMouse = () => _isUsingMouse;
const isUsingKeyboard = () => _isUsingKeyboard; // XXX: we could improve to exclude shift/ctrl/cmd/alt
// XXX: an improvement here could be to detect (guess) if we are using fingers!
//       @see https://codeburst.io/the-only-way-to-detect-touch-with-javascript-7791a3346685
//       @see https://stackoverflow.com/a/18001239
// const isUsingFinger = () => {};

const runHandlers = () => {
	handlers.forEach((handler) => {
		handler({
			touch: isUsingTouch(),
			mouse: isUsingMouse(),
			keyboard: isUsingKeyboard(),
		});
	});
};

/**
 * Know how the user interacts with the website (mouse, keyboard,…)
 */
export default function useInputDevice(handler = undefined) {
	useEffect(() => {
		count++; // increment count of mounted components using this hook

		if (typeof handler !== 'undefined') {
			handler({
				touch: isUsingTouch(),
				mouse: isUsingMouse(),
				keyboard: isUsingKeyboard(),
			});
			handlers.add(handler);
		}

		if (document && count === 1) {
			document.addEventListener('touchstart', onTouchStart);
			document.addEventListener('keydown', onKeyDown);
			// WARNING: these events are also triggered on touch, on some browsers. This is why as soon as we detect touch we stop caring about them (see above).
			document.addEventListener('mousedown', onMouseEvent);
			document.addEventListener('mousemove', onMouseEvent);

			// if we don't know within a reasonable timing if the user is using touch or mouse, we take a guess
			// on a small screen it's quite safe to assume we are on a touch device
			setTimeout(() => {
				const vw = window.innerWidth;
				if (
					typeof isUsingMouse() === 'undefined' &&
					typeof isUsingTouch() === 'undefined' &&
					vw <= 1024 &&
					vw > 0 // just to make sure the measurement was valid
				) {
					_isUsingTouch = true;
					runHandlers();
				}
			}, 500);
		}

		// cleanup
		return () => {
			count--; // increment count of mounted components using this hook

			if (typeof handler !== 'undefined') {
				handlers.delete(handler);
			}

			if (document && count === 1) {
				document.removeEventListener('touchstart', onTouchStart);
				document.removeEventListener('keydown', onKeyDown);
				document.removeEventListener('mousedown', onMouseEvent);
				document.removeEventListener('mousemove', onMouseEvent);
			}
		};
	}, [handler]);

	return { isUsingTouch, isUsingMouse, isUsingKeyboard };
}
