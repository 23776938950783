import React, { useState, useRef, useEffect } from 'react';
import Helmet from 'react-helmet';
import cx from 'classnames';
import { gsap, CustomEase, CSSPlugin } from '@assets/scripts/gsap/all';
import useInitialMount from '@assets/scripts/hooks/useInitialMount';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import useInputDevice from '@assets/scripts/hooks/useInputDevice';
// vars
const DATA_ANIMATION_PAGE = '[data-animation-page]';
const DATA_TARGET_BORDER = '[data-target-border]';
const CLASS_USING_MOUSE = 'using-mouse';
const CLASS_CAN_TOUCH = 'using-touch';
const VIEWPORT_THRESHOLD = 200;
const tlLabelsOpen = { showMenu: 0.3, showContent: 0.6 };
const tlLabelsClose = {
	hideContent: 0,
	hideMenu: 0.1,
	showPage: 0.7,
};

function preventClick(event) {
	event.preventDefault();
	event.stopPropagation();
}

export const PageContext = React.createContext();
export default ({ currentLanguage, children }) => {
	const isInitialMount = useInitialMount();
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isUsingMouse, setIsUsingMouse] = useState(undefined);
	const [isUsingTouch, setIsUsingTouch] = useState(undefined);
	const [fontsLoaded, setFontsLoaded] = useState(false);
	const isTransitioning = useRef(true);
	const tlMenu = useRef(gsap.timeline());
	const tlListMenuOpen = useRef({});
	const tlListMenuClose = useRef({});
	const tlListMenuPageTransition = useRef({});
	const tlListPageFadeOut = useRef({});
	const pageElList = useRef([]);
	const targetBorderList = useRef([]);
	const htmlClasses = cx(
		isUsingMouse && CLASS_USING_MOUSE,
		isUsingTouch && CLASS_CAN_TOUCH
	);
	// GDPR Services
	const [gdprServices, setGdprServices] = useState({});

	// update isUsingMouse on device change
	useInputDevice(({ touch, mouse }) => {
		setIsUsingTouch(touch);
		setIsUsingMouse(mouse);
	});

	gsap.registerPlugin(CustomEase, CSSPlugin);

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		pageElList.current = document.querySelectorAll(DATA_ANIMATION_PAGE);
		targetBorderList.current = document.querySelectorAll(
			DATA_TARGET_BORDER
		);

		targetBorderList.current.forEach((el) => {
			el.addEventListener('click', (event) => preventClick(event));
		});

		// ⏳ save timeline functions
		tlListMenuOpen.current['page'] = tlMenuOpen;
		tlListMenuClose.current['page'] = tlMenuClose;
		tlListPageFadeOut.current['page'] = tlFadeOut;
		document.fonts.ready.then(() => setFontsLoaded(true));

		return () => {
			targetBorderList.current.forEach((el) => {
				el.removeEventListener('click', (event) => preventClick(event));
			});

			delete tlListMenuOpen.current['page'];
			delete tlListMenuClose.current['page'];
			delete tlListPageFadeOut.current['page'];
		};
	}, []);
	/* eslint-enable react-hooks/exhaustive-deps */

	useEffect(() => {
		if (!fontsLoaded) return;

		// ⏳ fade in animation
		tlFadeIn();
	}, [fontsLoaded]);

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		if (isInitialMount) {
			return;
		}

		// clear timeline
		tlMenu.current.clear().kill();
		// which animation should be played
		const tlList = isMenuOpen
			? tlListMenuOpen.current
			: tlListMenuClose.current;
		// populate timeline
		Object.entries(tlList).forEach(([key, timeline]) => {
			tlMenu.current.add(timeline(), 0);
		});
	}, [isMenuOpen]);
	/* eslint-enable react-hooks/exhaustive-deps */

	const tlPageOutTransition = () => {
		isTransitioning.current = true;
		document.documentElement.style.scrollBehavior = 'auto';

		// populate timeline
		const tl = gsap.timeline();
		Object.entries(tlListPageFadeOut.current).forEach(([key, timeline]) => {
			tl.add(timeline(), 0);
		});
	};

	const tlMenuTransition = () => {
		isTransitioning.current = true;
		document.documentElement.style.scrollBehavior = 'auto';
		const tl = gsap.timeline();
		Object.entries(tlListMenuPageTransition.current).forEach(
			([key, timeline]) => {
				tl.add(timeline(), 0);
			}
		);
	};

	// ✅ Fade in page
	const tlFadeIn = () => {
		const tl = gsap.timeline();
		// reset scroll
		tl.call(
			() => {
				window.scroll({ top: 0, left: 0, behavior: 'auto' });
				document.documentElement.style.scrollBehavior = '';
			},
			null,
			0
		);

		// elements filtered without data-intro
		const elementsFilteredNoIntro = Array.from(pageElList.current).filter(
			(el) => !el.dataset.intro
		);

		elementsFilteredNoIntro.forEach((el) => {
			const translateAmount =
				el.dataset.animationPage === 'true' ||
				el.dataset.animationPage === ''
					? 100
					: parseInt(el.dataset.animationPage);

			tl.fromTo(
				el,
				{
					y: translateAmount + 'px',
				},
				{
					duration: 0.55,
					ease: CustomEase.create('custom', '0.02, 0, 0, 1'),
					y: 0,
					clearProps: 'transform',
				},
				0.125
			)
				.fromTo(
					el,
					{
						opacity: 0,
					},
					{
						duration: 0.2,
						ease: 'none',
						opacity: 1,
					},
					0.125
				)
				.call(() => {
					clearAllBodyScrollLocks();
					isTransitioning.current = false;
				});
		});
		return tl;
	};

	// ❌ Fade out page
	const tlFadeOut = () => {
		const tl = gsap.timeline();
		const elementsOnViewport = getViewportElements();
		elementsOnViewport.forEach((el) => {
			// default amount 100
			const translateAmount =
				el.dataset.animationPage === 'true' ||
				el.dataset.animationPage === ''
					? 100
					: parseInt(el.dataset.animationPage);

			tl.call(
				() => {
					disableBodyScroll('', {
						reserveScrollBarGap: true,
					});
				},
				null,
				0
			)
				.to(
					el,
					{
						duration: 0.55,
						ease: CustomEase.create('custom', '1, 0, 0.98, 1'),
						y: translateAmount + 'px',
					},
					0
				)
				.to(
					el,
					{
						duration: 0.2,
						ease: 'none',
						opacity: 0,
					},
					tlLabelsOpen.showMenu
				);
		});
		return tl;
	};

	// ✅ Menu Open: page animation
	const tlMenuOpen = () => {
		const tl = gsap.timeline();
		// elements without header
		const elementsOnViewport = Array.from(getViewportElements()).filter(
			(el) => !el.dataset.header
		);
		elementsOnViewport.forEach((el) => {
			// default amount 100
			const translateAmount =
				el.dataset.animationPage === 'true' ||
				el.dataset.animationPage === ''
					? 100
					: parseInt(el.dataset.animationPage);

			tl.to(
				el,
				{
					duration: 0.65,
					ease: CustomEase.create('custom', '1, 0, 0.98, 1'),
					y: translateAmount + 'px',
				},
				0
			).to(
				el,
				{
					duration: 0.2,
					ease: 'none',
					opacity: 0,
				},
				tlLabelsOpen.showMenu
			);
		});
		return tl;
	};

	// ❌ Menu Close: page animation
	const tlMenuClose = () => {
		const elementsOnViewport = getViewportElements();
		const tl = gsap.timeline();
		tl.clear()
			.to(
				elementsOnViewport,
				{
					duration: 0.55,
					ease: CustomEase.create('custom', '0.02, 0, 0, 1'),
					y: '0px',
					clearProps: 'transform',
				},
				tlLabelsClose.showPage
			)
			.to(
				elementsOnViewport,
				{
					duration: 0.2,
					ease: 'none',
					opacity: 1,
				},
				tlLabelsClose.showPage
			);
		return tl;
	};

	// 📐 Get elements on viewport
	const getViewportElements = () => {
		const elementsOnViewport = Array.from(pageElList.current).filter(
			(el) =>
				el.getBoundingClientRect().top >= -VIEWPORT_THRESHOLD ||
				el.getBoundingClientRect().bottom <=
					window.innerHeight + VIEWPORT_THRESHOLD
		);
		return elementsOnViewport;
	};

	const defaultContext = {
		fontsLoaded,
		isMenuOpen,
		isUsingMouse,
		isUsingTouch,
		setIsMenuOpen,
		tlListMenuOpen,
		tlListMenuClose,
		tlListMenuPageTransition,
		tlListPageFadeOut,
		tlLabelsOpen,
		tlLabelsClose,
		tlPageOutTransition,
		tlMenuTransition,
		currentLanguage,
		isTransitioning,
		gdprServices,
		setGdprServices,
	};

	return (
		<PageContext.Provider value={defaultContext}>
			<Helmet
				htmlAttributes={{
					class: htmlClasses,
				}}
			>
				{gdprServices.gtm && gdprServices.gtm.isAccepted && (
					<script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-5QWXT6N');`}</script>
				)}
			</Helmet>
			{children}
		</PageContext.Provider>
	);
};
