let idCounter = 0;

/**
 * Returns a unique id
 * @param {string} prefix
 */
const uniqueId = (prefix) => {
	const id = ++idCounter;
	return prefix + id;
};

export default uniqueId;
